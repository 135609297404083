import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import Vuelidate from 'vuelidate';
import { VueReCaptcha } from 'vue-recaptcha-v3';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faLocationArrow, faCheck, faBars, faTimes, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faLocationArrow, faCheck, faBars, faTimes, faChevronDown);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(Vuelidate);
Vue.use(VueReCaptcha, { siteKey: '6Lcc9AMcAAAAAIihkgnQZdN3NShD-7T-QEQwxBm_', loaderOptions: { useEnterprise: true } });

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

// Old recaptcha key => 6LeORZccAAAAAO-S_MMoHiHOjfD5pS2G2uPUDYzo
